import React, { useState, useEffect } from "react"
import Menu from '../json/menu.json'
import { map, omit, clone, union } from 'lodash'
import { Link } from 'gatsby'
import ChevronRight from "../svg/ChevronRight"
import ChevronDown from "../svg/ChevronDown"
import { addCss } from '../lib/ReactUtils'

addCss(`.active { @apply px-2 -ml-2 text-white bg-primary-dark rounded hover:text-white !important }`)
const ref = { }

export function setOpen(titles) {
  if (!ref.setOpen || !titles) return
  ref.setOpen(union(ref.open, titles))
}

export default function MenuComponent({onClick}) {
    const [open, setOpen] = useState([])
    useEffect(() => {
      ref.setOpen = setOpen
      ref.open = open
      return () => {
        ref.setOpen = null
        ref.open = null
      }
    }, [open, setOpen])

    const onClickMenu = (e) => {
        const currentTarget = e.currentTarget
        const title = currentTarget.getAttribute('data-title')
        const index = open.indexOf(title)
        const _open = clone(open)
        if (index == -1) {
            _open.push(title)
        }
        else {
            _open.splice(index, 1)
        }
        setOpen(_open)
    }

    const isOpen = (title) => open.includes(title)

    return (
        <>
            { Menu._.map((item, index) => (
              <div key={item[1]} className='mt-1'>
                <Link
                  onClick={onClick}
                  className={'basic duration-200 hover:text-primary ' + (item[2] ? 'text-orange-600 ' : '') + (item[3] ? 'font-bold' : '')}
                  activeClassName='active'
                  to={item[1]}
                >
                  {item[0]}
                </Link>
              </div>
            ))}
            { map(omit(Menu, '_'), (menu, title) => {
                const _isOpen = isOpen(title)
                return (
                    <div key={title}>
                        <div className='mt-6 cursor-pointer font-bold mt-3' data-title={title} onClick={onClickMenu}>
                            {_isOpen ? <ChevronDown/> : <ChevronRight/>}
                            {title}
                        </div>
                        <div className={_isOpen ? 'ml-3' : 'ml-3 h-0 overflow-hidden'}>
                            { menu._.map((item) => (
                              <div key={item[1]} className='ml-3 mt-1'>
                                <Link
                                  onClick={onClick}
                                  className={'basic hover:text-primary '+ (item[2] ? 'text-orange-600 ' : '') + (item[3] ? 'font-bold' : '')}
                                  activeClassName='active'
                                  to={item[1]}
                                >
                                    {item[0]}
                                </Link>
                              </div>
                            ))}
                            { map(omit(menu, '_'), (submenu, subtitle) => {
                                const _title = title+'-'+subtitle
                                const _isOpen = isOpen(_title)
                                return (
                                    <div key={_title} className='ml-3'>
                                        <div className='mt-1 cursor-pointer font-bold'  data-title={_title} onClick={onClickMenu}>
                                            {_isOpen ? <ChevronDown/> : <ChevronRight/>}
                                            {subtitle}
                                        </div>
                                        <div className={_isOpen ? 'ml-5' : 'ml-5 h-0 overflow-hidden'}>
                                          { submenu._.map((subitem) => (
                                            <div key={subitem[1]} className='mt-1'>
                                            <Link
                                              onClick={onClick}
                                              className={'basic hover:text-primary ' + (subitem[2] ? 'text-orange-600 ' : '') + (subitem[3] ? 'font-bold' : '')}
                                              activeClassName='active'
                                              to={subitem[1]}
                                            >
                                              {subitem[0]}
                                            </Link>
                                          </div>
                                          ))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </>
    )
}
