import React, { useState, useEffect, useRef } from "react"
import { useClasses } from '../lib/ReactUtils'
import Logo from '../svg/Logo'
import Icon from '../images/icon-blue.svg'
import '../styles/Main'
import Menu from './Menu'
import Search from "./Search"

// we need to hide startup artifacts without waiting for external css to load
const fadeInCss = `
  @keyframes fade-in {0% {opacity: 0;} 75% {opacity: 0;} 100% {opacity: 1;}}
  .fade-in { animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; }
` 

const ref = {}
export function closeMenu() {
  ref.setIsMenuOpen && ref.setIsMenuOpen(false)
}

export default function Layout({ children }) {
  const root = useClasses()
  const lastClick = useRef(0)
  const sidebarRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    ref.setIsMenuOpen = setIsMenuOpen
  }, [setIsMenuOpen])

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const openMenu = () => {
    setIsMenuOpen(true)      
  }

  const onClick = (e) => {
    document.body.scrollTop = 0
    closeMenu()
  }

  const onDocumentClick = function(e) {
    const now = new Date().getTime()
    if (now - lastClick.current < 500) return
    lastClick.current = now
    const target = e.target
    if (sidebarRef.current && sidebarRef.current.contains(target)) return
    closeMenu()
  }

  useEffect(() => {
    root.current && root.current.addEventListener('click', onDocumentClick)
    return () => root.current && root.current.removeEventListener('click', onDocumentClick)
  }, [])

  return (
    <>
      <style dangerouslySetInnerHTML={{__html:fadeInCss}}/>
      <div ref={root} className={'fade-in relative flex mx-auto my-0 md:h-screen max-w-400' +  (isMenuOpen ? ' overflow-y-hidden' : '')} >
        <div ref={sidebarRef} className={'md:h-screen z-20 fixed top-0 w-85 md:w-62 lg:w-72 xl:w-90 font-thin flex flex-col flex-shrink-0 shadow md:shadow-none pb-28 md:pb-15 pl-7 pr-2 md:px-4 bg-white border-r border-gray-300 transform duration-200 overflow-y-auto ' + (isMenuOpen ? 'translate-x-0 h-screen' : '-translate-x-full') + ' md:translate-x-0'}>
          <div className='flex justify-between'>
            <a href="https://www.oneoffice.ca/erp" className='basic' alt='OneOffice ERP'>
              <Logo className='duration-300 transition-color hover:text-primary w-full w-52 mt-6 mb-12 text-center text-gray-700'/>
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" onClick={closeMenu} className="mt-5 md:hidden h-8 w-8 cursor-pointer stroke-black hover:stroke-primary" fill="none" viewBox="0 0 24 24" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <Search/>
          <Menu onClick={onClick}/>
        </div>
        <div className='relative md:ml-62 lg:ml-72 xl:ml-90 px-4 lg:pl-7 flex-grow-1 block md:h-screen w-full'>
          <div className='md:hidden z-10 fixed flex flex-row top-0 left-0 py-4 bg-white/85 w-full'>
            <button className="group flex flex-col ml-7 mr-5" onClick={openMenu}>
              <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1 group-hover:bg-primary"/>
              <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1 group-hover:bg-primary"/>
              <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1 group-hover:bg-primary"/>
            </button>
            <a href="https://www.oneoffice.ca/erp" className='basic' alt='OneOffice'>
              <img src={Icon} className="w-8 h-8 -mt-1" alt='OneOffice Logo'/>
            </a>
          </div>
          {children}
        </div>
      </div>
    </>
  )

}
