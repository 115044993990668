import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { useFlexSearch } from 'react-use-flexsearch'
import { useClasses } from '../lib/ReactUtils'
import { Link } from 'gatsby'
import { closeMenu } from './Layout'

export default function Search() {
  const root = useClasses()
  // https://labs.walltowall.com/easy-client-side-search-for-gatsby-sites/
  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)
  const index = queryData.localSearchPages.index
  const store = queryData.localSearchPages.store
  // https://www.emgoto.com/gatsby-search/
  const [searchQuery, setSearchQuery] = useState('')
  const [value, setValue] = useState('')
  const results = useFlexSearch(searchQuery, index, store)

  const onChange = (e) => {
    const _value = e.currentTarget.value
    setValue(_value)
    if (_value.length > 2) setSearchQuery(_value.toLowerCase())
    else setSearchQuery('')
  }

  const onClick = () => {
    setValue('')
    setSearchQuery('')
    closeMenu()
  }

  return (
    <div className='relative' ref={root}>
      <div className="flex justify-center relative">
        <div className="mb-1 xl:w-96">
          <div className="input-group border border-solid border-gray-300 rounded relative flex items-stretch w-full mb-2 ">
            <input type="search" value={value} className="form-control relative flex-auto min-w-0 block w-full pl-2 pr-0 py-1.5 text-base font-normal text-gray-700 m-0 focus:outline-none" placeholder="Search" aria-label="Search" onChange={onChange}/>
            <button className="inline-block pl-2 pr-2 py-2.5 text-gray-700 font-medium text-xs leading-tight uppercase rounded flex items-center" type="button" id="button-addon2">
              <svg aria-hidden="true" focusable="false" data-icon="search" className="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      { results && results.length ? 
        <div className="absolute bg-gray-100 flex flex-col gap-y-3 z-10 border border-solid border-gray-300 rounded p-2">
          { results.slice(1,20).map(({topic, title, slug}, index) => (
            <Link className='basic group' key={index} to={slug} onClick={onClick}>
              <span className='leading-tight font-semibold group-hover:text-orange-600 pr-1'>{title}</span>
              <span className='leading-tight font-thin group-hover:text-orange-500 pr-1 text-sm'>[{topic}] </span>
            </Link>)
          )}
        </div> : null
      }
    </div>

  )
}


// eslint-disable-next-line
function filterResults(results) {
  if (!results || results.length === 0) return []
  const filteredResults = []
  var currentResult = results.shift()
  currentResult.score = 1
  let i = 0
  while (i < results.length && filteredResults.length < 15) {
    const newResult = results[i]
    if (newResult.slug === currentResult.slug) ++currentResult.score
    else {
      filteredResults.push(currentResult)
      currentResult = newResult
      currentResult.score = 1
    }
    ++i
  }
  filteredResults.push(currentResult)
  return filteredResults.sort(function(a, b) { return b.score - a.score})
}
